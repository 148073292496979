import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

const NotFoundPage = () => (
  <Layout container={true}>
    <SEO title="404: Nicht gefunden" />
    <div style={{textAlign:"center",marginTop:80,fontSize:100,fontFamily:"verdana,arial,sans"}}>:(</div>
    <h1 style={{textAlign:"center",marginTop:30}}>Entschuldigung,</h1>
    <p style={{textAlign:"center",marginBottom:20}}>diese Seite wurde nicht gefunden.</p>
    <div style={{textAlign:"center",marginBottom:80}}>
        <a className="btn btn-primary-outline" style={{textAlign:"center !important",margin:"auto !important",display:"inline-block !important"}} href="/">
            <FontAwesomeIcon icon={faHome}/>&nbsp;
            Zur Startseite
        </a>
    </div>
  </Layout>
)

export default NotFoundPage
